<template>
  <div class="c12-card logincaed">
    <div class="c12-card__title">
      <div class="c12-card__title__item">
        <div class="button-primary active">
          <span class="button-primary__text">忘记密码</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content">
      <div class="c12-card__content__item mb-16">
        <div class="stepbar">
          <div class="stepbar__item" :class="{'active': activeStep === 1}">
            <span class="stepbar__item__number">1</span>
            <span class="stepbar__item__text">输入手机号码</span>
          </div>
          <div class="stepbar__item" :class="{'active': activeStep === 2}">
            <span class="stepbar__item__number">2</span>
            <span class="stepbar__item__text">输入新密码</span>
          </div>
        </div>
      </div>
      <template v-if="activeStep === 1">
        <div class="c12-card__content__item" style="margin-bottom: 150px;">
          <div class="c8-select">
            <div class="c8-select__title">
              <span class="c8-select__title__text">手机号码:</span>
            </div>
            <div class="c8-select__content">
              <input v-model="mobile_form.mobileNumber" type="text" placeholder="请输入您11位的手机号码">
              <span
                v-if="rules_valid['mobileNumber'].notValid"
                class="c8-select__content__textarea red"
              >*请输入您11位的手机号码</span>
            </div>
          </div>
        </div>
        <div class="c12-card__content__item enter" style="margin-bottom: 100px;">
          <a class="button-outline active" @click="findPwdMobileConfirm">
            <span class="button-outline__text">下一步</span>
          </a>
          <a class="button-primary" @click="toLogin">
            <span class="button-outline__text">取消</span>
          </a>
        </div>
      </template>
      <template v-else-if="activeStep === 2">
        <div class="c12-card__content__item">
          <div class="c8-select">
            <div class="c8-select__title">
              <span class="c8-select__title__text">新密码:</span>
            </div>
            <div class="c8-select__content">
              <input
                v-model="newpwd_form.newPassword"
                type="password"
                placeholder="6~20个字母、数字组成，分大小写"
              >
              <span
                v-if="rules['newPassword'].notValid"
                class="c8-select__content__textarea red"
              >*6~20个字母、数字组成，分大小写</span>
            </div>
          </div>
        </div>
        <div class="c12-card__content__item" style="margin-bottom: 100px;">
          <div class="c8-select">
            <div class="c8-select__title">
              <span class="c8-select__title__text">确认密码:</span>
            </div>
            <div class="c8-select__content">
              <input v-model="newpwd_form.confirmedPwd" type="password" placeholder="请重复输入您的密码">
              <span
                v-if="rules['confirmedPwd'].notValid"
                class="c8-select__content__textarea red"
              >*请重复输入您的密码</span>
            </div>
          </div>
        </div>
        <div class="c12-card__content__item enter" style="margin-bottom: 100px;">
          <a class="button-outline active" @click="findPwdNewPwdConfirm">
            <span class="button-outline__text">送出</span>
          </a>
          <a class="button-primary" @click="toLogin">
            <span class="button-outline__text">取消</span>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { findPwdValidate_web, findPwdNewPwd_web } from '@/api/desktop/member'
export default {
  name: 'ForgotForm',
  data() {
    return {
      activeStep: 1,
      mobile_form: {
        mobileNumber: ''
      },
      newpwd_form: {
        newPasswordToken: '',
        newPassword: '',
        confirmedPwd: ''
      },
      rules_valid: {
        mobileNumber: {
          validation: this.mobileNumberValidation,
          notValid: false
        }
      },
      rules: {
        newPassword: {
          validation: this.newPasswordValidation,
          notValid: false
        },
        confirmedPwd: {
          validation: this.confirmedPwdValidation,
          notValid: false
        }
      }
    }
  },
  methods: {
    async findPwdMobileConfirm() {
      const valid = await this.validation_mobile()
      if (!valid) return false
      const [data, error] = await findPwdValidate_web(this.mobile_form)
      if (error) {
        this.$message({
          message: error.data.message,
          type: 'error',
          duration: 1500
        })
        return
      }
      this.newpwd_form.newPasswordToken = data.data.data.newPasswordToken
      this.activeStep = 2
    },
    async findPwdNewPwdConfirm() {
      const valid = await this.validation()
      if (!valid) return false
      const [, error] = await findPwdNewPwd_web({
        newPasswordToken: this.newpwd_form.newPasswordToken,
        newPassword: this.newpwd_form.newPassword
      })
      if (error) {
        this.$message({
          message: error.data.message,
          type: 'error',
          duration: 1500
        })
        return
      }
      this.$message({
        message: '修改成功',
        type: 'success',
        duration: 1500,
        onClose: window.setTimeout(() => {
          this.toLogin()
        }, 2000)
      })
    },
    validation_mobile() {
      let valid = true
      return new Promise((resolve, reject) => {
        Object.keys(this.rules_valid).forEach((key) => {
          const isValid = this.rules_valid[key].validation()
          valid = valid && isValid
        })
        if (!valid) {
          console.log('資料不正確填寫！')
          resolve(false)
        }
        resolve(true)
      })
    },
    validation() {
      let valid = true
      return new Promise((resolve, reject) => {
        Object.keys(this.rules).forEach((key) => {
          const isValid = this.rules[key].validation()
          valid = valid && isValid
        })
        if (!valid) {
          console.log('資料不正確填寫！')
          resolve(false)
        }
        resolve(true)
      })
    },
    mobileNumberValidation() {
      if (this.mobile_form.mobileNumber.length !== 11) {
        this.rules_valid['mobileNumber'].notValid = true
        return false
      }
      this.rules_valid['mobileNumber'].notValid = false
      return true
    },
    newPasswordValidation() {
      if (this.newpwd_form.newPassword.length < 6 || this.newpwd_form.newPassword.length > 20) {
        this.rules['newPassword'].notValid = true
        return false
      }
      this.rules['newPassword'].notValid = false
      return true
    },
    confirmedPwdValidation() {
      if (!this.newpwd_form.confirmedPwd || this.newpwd_form.newPassword !== this.newpwd_form.confirmedPwd) {
        this.rules['confirmedPwd'].notValid = true
        return false
      }
      this.rules['confirmedPwd'].notValid = false
      return true
    },
    toLogin() {
      this.$emit('change-mode', 'login')
    }
  }
}
</script>
