<template>
  <div class="c12-card__content">
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">登录帐号:</span>
        </div>
        <div class="c8-select__content">
          <input v-model="register_form.name" type="text" placeholder="6-8个字符组成(不含中文)">
          <span
            v-if="rules['name'].notValid"
            class="c8-select__content__textarea red"
          >*6-8个字符组成(不含中文)</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">登录密码:</span>
        </div>
        <div class="c8-select__content">
          <input v-model="register_form.password" type="password" placeholder="6~20个字母、数字组成，分大小写">
          <span
            v-if="rules['password'].notValid"
            class="c8-select__content__textarea red"
          >*6~20个字母、数字组成，分大小写</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">确认密码:</span>
        </div>
        <div class="c8-select__content">
          <input v-model="register_form.confirmedPwd" type="password" placeholder="请重复输入您的密码">
          <span
            v-if="rules['confirmedPwd'].notValid"
            class="c8-select__content__textarea red"
          >*请重复输入您的密码</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">真实姓名:</span>
        </div>
        <div class="c8-select__content">
          <input v-model="register_form.realName" type="text" placeholder="同开户银行，不能修改">
          <span
            v-if="rules['realName'].notValid"
            class="c8-select__content__textarea red"
          >*请输入真实姓名</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">手机号码:</span>
        </div>
        <div class="c8-select__content">
          <input v-model="register_form.mobileNumber" type="text" placeholder="请输入您11位的手机号">
          <span
            v-if="rules['mobileNumber'].notValid"
            class="c8-select__content__textarea red"
          >*请输入您11位的手机号</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">验证码:</span>
        </div>
        <div class="c8-select__content">
          <div class="passcode">
            <input v-model="register_form.captcha" class="passcode__input" type="text" style="min-width: 0;">
            <div class="passcode__img" style="cursor: pointer;" @click="getCaptcha">
              <img class="passcode__img__" :src="getCaptchaSrc" alt="找不到图片！">
            </div>
          </div>
          <span
            v-if="rules['captcha'].notValid"
            class="c8-select__content__textarea red"
          >*请输入验证码</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item enter">
      <a class="button-outline active" @click="register">
        <span class="button-outline__text">创建帐号</span>
      </a>
    </div>
  </div>
</template>

<script>
import { register_web, login_web, verifyCaptcha } from '@/api/desktop/member'
export default {
  name: 'RegisterForm',
  data() {
    return {
      register_form: {
        name: '',
        password: '',
        confirmedPwd: '',
        realName: '',
        mobileNumber: '',
        captcha: ''
      },
      rules: {
        name: {
          validation: this.nameValidation,
          notValid: false
        },
        password: {
          validation: this.passwordValidation,
          notValid: false
        },
        confirmedPwd: {
          validation: this.confirmedPwdValidation,
          notValid: false
        },
        realName: {
          validation: this.realNameValidation,
          notValid: false
        },
        mobileNumber: {
          validation: this.mobileNumberValidation,
          notValid: false
        },
        captcha: {
          validation: this.captchaValidation,
          notValid: false
        }
      },
      getCaptchaSrc: `/getCaptcha?mode=web&call=${Date.now()}`
    }
  },
  methods: {
    async register() {
      const valid = await this.validation()
      if (!valid) {
        this.$message({
          message: '资料填写错误, 请重新检视资料',
          type: 'error',
          duration: 1500
        })
        return
      }
      const [, error] = await register_web({
        avatar: this.register_form.avatar,
        name: this.register_form.name,
        password: this.register_form.password,
        realName: this.register_form.realName,
        mobileNumber: this.register_form.mobileNumber
      })
      if (error) {
        this.$message({
          message: error.data.message,
          type: 'error',
          duration: 1500
        })
        return
      }
      this.$message({
        message: '注册成功',
        type: 'success',
        duration: 1500
      })
      this.login()
    },
    async login() {
      const [response, error] = await login_web({
        name: this.register_form.name,
        password: this.register_form.password
      })
      if (error) {
        this.$message({
          message: error.data.message,
          type: 'error',
          duration: 1500
        })
        return
      }
      // 因為錯誤訊息如果被解構變成無法捕捉到, 所以api請求改成不解構回傳值
      const loginInfo = response.data.data
      await this.$store.dispatch('member/setLoginInfo', loginInfo)
      this.$router.push('/member')
    },
    validation() {
      const promises = Object.keys(this.rules).map((key) => this.rules[key].validation())
      return new Promise((resolve, reject) => {
        Promise.all(promises)
          .then((res) => {
            if (res.findIndex(each => !each) > -1) {
              resolve(false)
            } else resolve(true)
          })
      })
    },
    nameValidation() {
      const containChinese = /[\u4e00-\u9fa5]/.test(this.register_form.name)
      const wrongLength = this.register_form.name.length < 6 || this.register_form.name.length > 8
      if (containChinese || wrongLength) {
        this.rules['name'].notValid = true
        return false
      }
      this.rules['name'].notValid = false
      return true
    },
    passwordValidation() {
      const containChinese = /[\u4e00-\u9fa5]/.test(this.register_form.password)
      const wrongLength = this.register_form.password.length < 6 || this.register_form.password.length > 20
      if (containChinese || wrongLength) {
        this.rules['password'].notValid = true
        return false
      }
      this.rules['password'].notValid = false
      return true
    },
    confirmedPwdValidation() {
      const notTheSamePwd = this.register_form.confirmedPwd !== this.register_form.password
      if (!this.register_form.confirmedPwd || notTheSamePwd) {
        this.rules['confirmedPwd'].notValid = true
        return false
      }
      this.rules['confirmedPwd'].notValid = false
      return true
    },
    realNameValidation() {
      if (!/^[\u4e00-\u9fa5]+$/.test(this.register_form.realName) ||
        this.register_form.realName.length > 20 || this.register_form.realName.length === 0) {
        this.rules['realName'].notValid = true
        return false
      }
      this.rules['realName'].notValid = false
      return true
    },
    mobileNumberValidation() {
      const isNumber = /^[\d]+$/.test(this.register_form.mobileNumber)
      const betweenElevenDigits = this.register_form.mobileNumber.length === 11
      if (!isNumber || !betweenElevenDigits) {
        this.rules['mobileNumber'].notValid = true
        return false
      }
      this.rules['mobileNumber'].notValid = false
      return true
    },
    async captchaValidation() {
      const res = await verifyCaptcha({
        captcha: this.register_form.captcha
      })
      if (!res.data.valid) {
        this.rules['captcha'].notValid = true
        return false
      }
      this.rules['captcha'].notValid = false
      return true
    },
    getCaptcha() {
      this.getCaptchaSrc = `/getCaptcha?mode=web&call=${Date.now()}`
    }
  }
}
</script>
