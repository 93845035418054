<template>
  <div class="container__content">
    <div class="content-12">
      <div class="wrapper">
        <div class="content-12__content">
          <template v-if="mode === 'register' || mode === 'login'">
            <div class="c12-card logincaed">
              <div class="c12-card__title">
                <div class="c12-card__title__item">
                  <a
                    class="button-primary"
                    :class="{'active': mode === 'login'}"
                    @click="changeMode('login')"
                  >
                    <span class="button-primary__text">登录</span>
                  </a>
                </div>
                <div class="c12-card__title__item">
                  <a
                    class="button-primary"
                    :class="{'active': mode === 'register'}"
                    @click="changeMode('register')"
                  >
                    <span class="button-primary__text">免费注册</span>
                  </a>
                </div>
              </div>
              <template v-if="mode === 'login'">
                <login-form @change-mode="changeMode($event)" />
              </template>
              <template v-else-if="mode === 'register'">
                <register-form />
              </template>
            </div>
          </template>
          <template v-if="mode === 'forgot'">
            <forgot-form @change-mode="changeMode($event)" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginForm from '@/components/web/pages/member/loginForm'
import registerForm from '@/components/web/pages/member/registerForm'
import forgotForm from '@/components/web/pages/member/forgotForm'
export default {
  name: 'WebUserLogin',
  components: {
    loginForm,
    registerForm,
    forgotForm
  },
  data() {
    return {
      mode: ''
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.mode = this.$route.query.mode || 'login'
      }
    }
  },
  created() {
    this.mode = this.$route.query.mode || 'login'
  },
  methods: {
    changeMode(formMode) {
      this.mode = formMode
    }
  }
}
</script>
