<template>
  <div class="c12-card__content">
    <div class="c12-card__content__item">
      <div class="c12-imgbox"><img src="@/assets/desktop/img/login-img.jpg" alt=""></div>
    </div>
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">帐号:</span>
        </div>
        <div class="c8-select__content">
          <input v-model="login_form.name" type="text" placeholder="请输入帐号">
          <span
            v-if="rules['name'].notValid"
            class="c8-select__content__textarea red"
          >*请输入帐号</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">密码:</span>
        </div>
        <div class="c8-select__content">
          <input v-model="login_form.password" type="password" placeholder="请输入密码">
          <span
            v-if="rules['password'].notValid"
            class="c8-select__content__textarea red"
          >*请输入密码</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item">
      <div class="c8-select">
        <div class="c8-select__title">
          <span class="c8-select__title__text">验证码:</span>
        </div>
        <div class="c8-select__content">
          <div class="passcode">
            <input v-model="login_form.captcha" class="passcode__input" type="text" placeholder="验证码" style="min-width: 0;">
            <div class="passcode__img" style="cursor: pointer; width:50%;" @click="getCaptcha">
              <img class="passcode__img__" :src="getCaptchaSrc" alt="找不到图片！">
            </div>
          </div>
          <span
            v-if="rules['captcha'].notValid"
            class="c8-select__content__textarea red"
          >*请输入验证码</span>
        </div>
      </div>
    </div>
    <div class="c12-card__content__item enter">
      <a class="button-outline active" @click="login">
        <span class="button-outline__text">登录</span>
      </a>
      <a class="button-primary" @click="toForgot">
        <span class="button-outline__text">忘记密码</span>
      </a>
    </div>
  </div>
</template>

<script>
import { login_web, verifyCaptcha } from '@/api/desktop/member'
export default {
  name: 'LoginForm',
  data() {
    return {
      login_form: {
        name: '',
        password: '',
        captcha: ''
      },
      rules: {
        name: {
          validation: this.nameValidation,
          notValid: false
        },
        password: {
          validation: this.passwordValidation,
          notValid: false
        },
        captcha: {
          validation: this.captchaValidation,
          notValid: false
        }
      },
      getCaptchaSrc: `/getCaptcha?mode=web&call=${Date.now()}`
    }
  },
  methods: {
    toForgot() {
      this.$emit('change-mode', 'forgot')
    },
    async login() {
      const valid = await this.validation()
      if (!valid) return
      const [response, error] = await login_web({
        name: this.login_form.name,
        password: this.login_form.password
      })
      if (error) {
        this.$message({
          message: error.data.message,
          type: 'error',
          duration: 1500
        })
        return
      }
      // 因為錯誤訊息如果被解構變成無法捕捉到, 所以api請求改成不解構回傳值
      const loginInfo = response.data.data
      await this.$store.dispatch('member/setLoginInfo', loginInfo)
      this.$router.push('/member')
    },
    validation() {
      const promises = Object.keys(this.rules).map((key) => this.rules[key].validation())
      return new Promise((resolve, reject) => {
        Promise.all(promises)
          .then((res) => {
            if (res.findIndex(each => !each) > -1) {
              resolve(false)
            } else resolve(true)
          })
      })
    },
    nameValidation() {
      if (!this.login_form.name) {
        this.rules['name'].notValid = true
        return false
      }
      this.rules['name'].notValid = false
      return true
    },
    passwordValidation() {
      if (!this.login_form.password) {
        this.rules['password'].notValid = true
        return false
      }
      this.rules['password'].notValid = false
      return true
    },
    async captchaValidation() {
      const res = await verifyCaptcha({
        captcha: this.login_form.captcha
      })
      if (!res.data.valid) {
        this.rules['captcha'].notValid = true
        return false
      }
      this.rules['captcha'].notValid = false
      return true
    },
    getCaptcha() {
      this.getCaptchaSrc = `/getCaptcha?mode=web&call=${Date.now()}`
    }
  }
}
</script>

